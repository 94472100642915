
import Vue from "vue";
import { tableColumnListAgencies } from "./AgenciesTable";
import { ITableWrapperColumns } from "@/types";
import {
  agencyMapActions,
  agencyMapGetters,
  agencyMapState
} from "@/store/modules/agency";
import * as selectOptions from "@/helpers/selectOptions";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import { IAgency } from "@/store/modules/agency/types";

interface IAgencyData {
  tableActions: any[];
  tableColumns: ITableWrapperColumns;
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
  agencyId: string;
  filters: any[];
  loadingText: string;
}
export default Vue.extend({
  name: "ListAgenciesTable",
  components: {
    NoResultsFound
  },
  props: {
    queryOverride: {
      type: Object,
      required: false,
      default: () => ({})
    },
    emitOnAction: {
      type: Boolean,
      default: false
    },
    fetchSubAgency: {
      type: Boolean,
      default: false
    },
    additionalTableActions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data(): IAgencyData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableActions: [...(this.additionalTableActions as any)],
      tableColumns: tableColumnListAgencies,
      loading: false,
      agencyId: "",
      filters: [
        {
          field: "status",
          title: "Status",
          options: selectOptions.agencyStatus
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        }
      ],
      loadingText: ""
    };
  },
  methods: {
    ...agencyMapActions(["deleteAgency", "getAgencies", "getSubAgencies"]),
    tableActionHandler(action: string) {
      switch (action) {
        default:
          if (this.emitOnAction) {
            return this.$emit("tableAction", action);
          }
          break;
      }
    },
    async fetchPageData(queryObject: Record<string, any>): Promise<any> {
      try {
        this.loadingText = "Fetching agencies. Please await...";
        this.$emit("scrollTop");
        const query = { ...queryObject, ...this.queryOverride };
        if (this.fetchSubAgency) {
          await this.getSubAgencies({
            query
          });
        } else {
          await this.getAgencies({
            query
          });
        }
      } catch (error) {
        this.$bugSnagClient.notify(error);
        this.errorMessage = "Error fetching agencies. Try again later.";
      } finally {
        this.loadingText = "";
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      if (this.currentUserIsAdmin) {
        this.$router.push(`/admin/agencies/${_id}/edit`).catch(() => {});
      } else {
        this.$router.push(`/agencies/${_id}`).catch(() => {});
      }
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "edit":
          this.$router.push(`/admin/agencies/${data._id}/edit`).catch(() => {});
          break;
        case "view":
          if (this.$isCurrentUserAdmin) {
            this.$router.push(`/admin/agencies/${data._id}`).catch(() => {});
          } else {
            this.$router.push(`/agencies/${data._id}`).catch(() => {});
          }
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteOneAgency(): Promise<void> {
      try {
        this.loadingText = "Deleting agency. Please await...";
        await this.deleteAgency(this.agencyId);
        this.$modal.hide("agenciesListModal");
        this.$appNotifySuccess("Agency Successfully Deleted");
        this.$router.replace("/admin/agencies").catch(() => {});
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loadingText = "";
      }
    },
    async deleteCallback(agencyId: any): Promise<void> {
      try {
        this.agencyId = agencyId;
        this.$modal.show("agenciesListModal");
      } catch (error) {
        if (error !== "cancel") {
          console.error(error);
        }
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...agencyMapGetters([
      "agencies",
      "getTotalRecords",
      "getAgencyLinks",
      "subAgencies"
    ]),
    ...agencyMapState(["makingApiRequest"]),
    agenciesData(): IAgency[] {
      return this.fetchSubAgency ? this.subAgencies : this.agencies;
    },
    currentUserIsAdmin(): boolean {
      return this.$getCurrentUser && this.$getCurrentUser.role === "admin";
    },
    tableRowActions(): any {
      return [
        { label: "View", value: "view", faIcon: "eye" },
        ...(this.currentUserIsAdmin
          ? [
              { label: "Edit", value: "edit", faIcon: "edit" },
              {
                label: "Delete",
                value: "delete",
                faIcon: "trash-alt",
                class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
              }
            ]
          : [])
      ];
    }
  }
});
